import React from 'react';
import { Box } from '@react-three/drei';
import { useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three';
import Wall3TexturePath from '../muri3.jpg';

function Wall3({ position, scale}) {
  const texture = useLoader(TextureLoader, Wall3TexturePath);
  return (
    <Box position={position} scale={scale}>      
      <meshStandardMaterial attach="material-1" />
      <meshStandardMaterial attach="material-0" color="#d3d3d3" map={texture}  />
      <meshStandardMaterial attach="material-2" />
      <meshStandardMaterial attach="material-3" />
      <meshStandardMaterial attach="material-4" />
      <meshStandardMaterial attach="material-5" color="#1c5e6c"/>
    </Box>
  );
}

export default Wall3;

