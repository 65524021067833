import React, { useRef, useEffect, useState } from 'react';
import { PointerLockControls, OrbitControls } from '@react-three/drei';
import { Canvas, useLoader, useFrame, } from '@react-three/fiber';

import { Raycaster, Vector2 } from 'three';
import { SpotLight } from '@react-three/drei';

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import Wall from './Wall';
import Ceiling from './Ceiling';
import Wall3 from './Wall3';
import Wall5 from './Wall5';
import Wall8 from './Wall8';
import Wall89 from './Wall89';
import Wall9 from './Wall9';
import Wall10 from './Wall10';
import Wall13 from './Wall13';
import Wall15 from './Wall15';
import Wall18 from './Wall18';
import Wall19 from './Wall19';
import ObjectBasement from './ObjectBasement';
import Floor from './Floor';
import SceneObject from './SceneObject';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import BoundingBox from './BoundingBox';
import './Museum.css';
//const minHeight = -10; 
//const maxHeight = 10; 

function Museum() {


  
  const [background, setBackground] = useState(
    'url(loading.gif)',
    'width: 50%',
    'background-size: 200px 100px',
  );
    useEffect(() => {
      const loadContent = async () => {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setBackground('#efefef');
      };
  
      loadContent();
    }, []);
  const [isMobile, setIsMobile] = useState(false);
  const [activeModal, setActiveModal] = useState(null);
  const modalRef = useRef(null);
  const controlsRef = useRef();

  
  useEffect(() => {
    console.log('Active Modal:', activeModal);
    console.log('Pointer Lock Element:', document.pointerLockElement);
  
    if (activeModal) {
      if (document.pointerLockElement) {
        console.log('Exiting pointer lock...');
        document.exitPointerLock();
      }
  
      setTimeout(() => {
        if (modalRef.current) {
          modalRef.current.focus();
        }
      }, 100);
  
      // Ensure controls are unlocked
      if (controlsRef.current) {
        controlsRef.current.unlock();
        console.log('Controls unlocked');
      }
  
      // Show default cursor style
      document.body.style.cursor = 'default';
    } else {
      // Lock controls and restore cursor style
      if (controlsRef.current) {
        controlsRef.current.lock();
        console.log('Controls locked');
      }
  
      // Hide the cursor in PointerLock document.body.style.cursor = 'none'; 
    }
  }, [activeModal]);
  

const openModal = (modalId) => {

  if (controlsRef.current) {
    controlsRef.current.unlock();
 
    console.log('Controls unlocked when modal opens');
  }

  setActiveModal(modalId);

};

// Function to close modal and re-lock controls
const closeModal = () => {
  // Deactivate the modal

  setActiveModal(null);


  // Lock controls after a short delay to ensure modal is fully closed
  setTimeout(() => {
    // Check if no modal is active and lock controls
    if (controlsRef.current) {
      controlsRef.current.lock();
      console.log('Controls locked after modal closes');
    }
  }, 100); // Adjust delay as needed
};
  const handleModalClick = (e) => {
    e.stopPropagation();
  };
  
  // Listen for pointer lock changes
  document.addEventListener('pointerlockchange', () => {
    const pointerLockElement = document.pointerLockElement || document.mozPointerLockElement || document.webkitPointerLockElement;
  
    if (!pointerLockElement) {
      console.log('Pointer is unlocked');
    } else {
      console.log('Pointer is locked on element:', pointerLockElement);
    }
  });
  



  return (
    <div className="museum-container" style={{
      background: background,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '750px 500px', // or 'cover', depending on your needs
      height: '100vh', // Ensure it takes full height
      width: '100%', // Ensure it takes full width
    }}>
      <Canvas
        className="canvas-container"
        style={{ width: '100%', height: '100%' }}
        shadows
        camera={{ position: [-15, 0, 4], fov: 65 }}
        gl={{ antialias: true, alpha: true }}
      >
        <ambientLight intensity={3} />
      {/* Render controls only when no modal is active */}
      {!activeModal && (
        isMobile ? 
          <OrbitControls target={[-12.8, 3.5, 0]} /> 
          : <PointerLockControls ref={controlsRef} />
      )}

      <Scene onObjectClick={openModal} />          
      </Canvas>


      <div className="custom-cursor"></div> 
           

        {/* Modal for modalObjekt15 */}
        {activeModal === 'modalObjekt15' && (
    <div className="modal-overlay" tabIndex="-1" ref={modalRef} onClick={handleModalClick}>
            <div className="modal-content">
    <div className="modal-body">
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <button type="button" className="btn-close  close-button" onClick={closeModal} aria-label="Close"></button></div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-6 object3d"><small style={{background:'red'}} onClick={closeModal}>Press ESC to control object rotation</small>
          <iframe title=""
            allowFullScreen
            mozAllowFullScreen="true"
            webkitAllowFullScreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            width="100%"
            height="100%"
            src="https://sketchfab.com/models/b6b891ceedf74e2ab1350dd65ced4dcf/embed?autostart=1"> 
          </iframe>
        </div>
        <div className="col-md-3 object-description" style={{ maxHeight: '650px', overflowY: 'scroll' }}>
          <h4><b>NEOLITHIC VASE</b></h4><small>New Stone Age, 7000-3700 years BC, Barc ( Korca), Kamnik (Kolonja), 
          Terracotta, Local culture</small><br /><br />A Neolithic vase with various functions, decorated 
          with parallel and spiral lines. The pottery has handles below the rim and reflects the Middle Neolithic with 
          brown and dark gray colors. The Kamnik vessel features a wide mouth and red spirals on an ocher background that 
          cover the surface.<hr /><h4><b>VAZO NEOLITIKE</b></h4><small>Koha e Gurit të Ri, 7000-3700 vjet para Kr. Barç 
          (Korçë), Kamnik (Kolonjë), Kultura Vendase, Baltë e pjekur </small><br /><br />Vazo neolitike me funksione
          të ndryshme, e zbukuruar me vija paralele dhe spirale. Qeramika ka vegjë poshtë buzëve dhe përfaqëson
          Neolitin e Mesëm me ngjyra kafe dhe gri të errët. Ena e Kamnikut, është punuat me grykë të gjerë dhe
          spirale të kuqe mbi sfond okër të cilat mbulojnë gjithë sipërfaqen.
          <br /><br /><br /><br /><img src="MUZEU_LOGO.png" width="200"/>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
    </div>
    </div>
    </div>
        )}
        {/* Modal for modalObjekt2 */}
        {activeModal === 'modalObjekt2' && (
    <div className="modal-overlay" tabIndex="-1" ref={modalRef} onClick={handleModalClick}>
            <div className="modal-content">
    <div className="modal-body">
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <button type="button" className="btn-close  close-button" onClick={closeModal} aria-label="Close"></button></div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-6 object3d"><small style={{background:'red'}} onClick={closeModal}>Press ESC to control object rotation</small>
          <iframe title=""
            allowFullScreen
            mozAllowFullScreen="true"
            webkitAllowFullScreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            width="100%"
            height="100%"
            src="https://sketchfab.com/models/ad4f85a0110a4af6b69655183559414b/embed?autostart=1"> 
          </iframe>
        </div>
        <div className="col-md-3 object-description" style={{ maxHeight: '650px', overflowY: 'scroll' }}>        
        <b><h4>STATUE OF MAGISTRATE</h4></b> <small>3rd century AD, Apolonia, Marble, Local culture</small><br/><br/>From the 1st century AD, 
        Apollonian art began to show strong Roman influences, characterized by the idealization of the devoted citizen, geometric forms, and 
        the coldness of character expressions. In the second half of the 2nd century AD, Apollonian art, while maintaining its high artistic level, 
        transitioned into a more schematic form where features were represented in a more generalized manner. A prime example of this style is the 
        figure known as 'The Great Magistrate,' believed to be a representation of the Roman Emperor Commodus (180-192 AD). The sculpture showcases 
        the sculptor's skill through the intricate details of the garments and the dignified stance of the figure.
        <hr />
        <b><h4>SHTATI I MAGJISTRATIT</h4></b> <small>Shek. III pas Kr., Apoloni, Mermer, Kultura vendase.</small><br/><br/>Nga shekulli I pas Krishtit, 
        arti apoloniat filloi të ndikohej fuqishëm nga arti romak, i cili karakterizohej nga idealizimi i qytetarit të përkushtuar, forma gjeometrike
         dhe një ftohtësi në shprehjen e karaktereve. Në gjysmën e dytë të shekullit II pas Krishtit, arti apoloniat, megjithëse ruajti nivelin e tij 
         të lartë artistik, kaloi në një skematizim më të përgjithësuar të formës dhe tipareve. Një shembull i shkëlqyer i këtij stili është figura e 
         njohur si 'Magjistrati i Madh', e cila besohet të përfaqësojë perandorin romak Komod (180-192 pas Krishtit). Skulptura tregon mjeshtërinë e 
         gdhendësit përmes detajeve të përpunuara të veshjeve dhe qëndrimit dinjitoz të figurës.       
        <br /><br /><br /><br /><img src="MUZEU_LOGO.png" width="200"/>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
    </div>
    </div>
    </div>
        )}
{/* Modal for modalObjekt3 */}
{activeModal === 'modalObjekt3' && (
    <div className="modal-overlay" tabIndex="-1" ref={modalRef} onClick={handleModalClick}>
            <div className="modal-content">
    <div className="modal-body">
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <button type="button" className="btn-close  close-button" onClick={closeModal} aria-label="Close"></button></div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-6 object3d"><small style={{background:'red'}} onClick={closeModal}>Press ESC to control object rotation</small>
          <iframe title=""
            allowFullScreen
            mozAllowFullScreen="true"
            webkitAllowFullScreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            width="100%"
            height="100%"
            src="https://sketchfab.com/models/dc8b6084f9db459e8413dabacd638819/embed?autostart=1"> 
          </iframe>
        </div>
        <div className="col-md-3 object-description" style={{ maxHeight: '650px', overflowY: 'scroll' }}>
        
        <b><h4>GOD APOLLO</h4></b> <small>4th century BC, Butrint (Saranda), Marble, 50 cm, Hellenistic culture, School of Praxiteles</small><br/><br/>
        Apollo was the god of beauty, the sun, medicine, music, art, law, and care for animals. This sculpture was discovered in 1928 by the 
        Italian archaeologist Luigi Maria Ugolini during excavations that led to the discovery of the ancient theater of Butrint. Initially 
        named "The Goddess of Butrint" (Dea di Butrinto), it was later identified as Apollo. In this artistic representation, Apollo is 
        idealized as the embodiment of masculine beauty, with delicate features resembling the feminine. He is depicted with a calm forehead 
        and dignified expression, with long hair tied in a knot atop his head, a distinctive characteristic of Apollo and the Praxitelean 
        school. The sculpture, carved from veined gray marble, presents an oval face, thin eyebrows, large eyes, and a small mouth with 
        full lips, all crafted with canonical precision. This unique sculpture exhibits hermaphroditic qualities; from the front, it appears 
        feminine, but viewed from the profile, the masculine features become clear.
        <hr />
        <b><h4>HYJI APOLLON</h4></b> <small>Shek. IV para Kr., Butrint (Sarandë), Marble, Mermer, 50 cm, Hellenistic culture, School of Praxiteles</small><br/><br/>
        Apoloni ishte perëndia e bukurisë, diellit, mjekësisë, muzikës, artit, ligjit dhe kujdesit ndaj kafshëve. Kjo skulpturë u zbulua 
        në vitin 1928 nga arkeologu italian Luigi Maria Ugolini, gjatë gërmimeve që nxorën në dritë teatrin antik të Butrintit. 
        Fillimisht e quajti "Perëndesha e Butrintit" (Dea di Butrinto), por më vonë u identifikua si Apoloni. Në paraqitjen artistike, ai 
        është idealizuar si mishërim i bukurisë mashkullore, me tipare delikate që i afrohen atyre femërore. Me ballë të qetë dhe pamje dinjitoze, 
        Apoloni paraqitet me flokë të gjatë të mbledhura në nyje mbi kokë, një karakteristikë e tij dhe e shkollës praksiteliane. Skulptura, 
        punuar në mermer me damarë të hirtë, përshkruan një figurë me fytyrë vezake, vetulla të holla, sy të mëdhenj dhe një gojë të vogël me 
        buzë të trasha, të gjitha të përpunuara me rigorozitet kanonik. Kjo vepër unike përmban tipare hermafrodite; nga pjesa ballore duket si 
        femër, ndërsa nga profili shquhet lehtësisht si mashkull.        
        <br /><br /><br /><br /><img src="MUZEU_LOGO.png" width="200"/>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
    </div>
    </div>
    </div>
        )}

{/* Modal for modalObjekt4 */}
{activeModal === 'modalObjekt4' && (
    <div className="modal-overlay" tabIndex="-1" ref={modalRef} onClick={handleModalClick}>
            <div className="modal-content">
    <div className="modal-body">
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <button type="button" className="btn-close  close-button" onClick={closeModal} aria-label="Close"></button></div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-6 object3d"><small style={{background:'red'}} onClick={closeModal}>Press ESC to control object rotation</small>
          <iframe title=""
            allowFullScreen
            mozAllowFullScreen="true"
            webkitAllowFullScreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            width="100%"
            height="100%"
            src="https://sketchfab.com/models/e6366e9843fd4699bfe583412f76bbbc/embed?autostart=1"> 
          </iframe>
        </div>
        <div className="col-md-3 object-description" style={{ maxHeight: '650px', overflowY: 'scroll' }}>
        <b><h4>Marble statue of a young man found near the bouleterion</h4></b><small>(possibly the emperor Geta)217-218 AD</small><br/><br/>
        This marble statue, depicting a young boy believed to be Emperor Geta, was found near the bouleuterion. It dates to 217-218 AD, a 
        period when imperial portraits emphasized authority and status. The statue displays high craftsmanship, particularly in the draping 
        of the garments and the calm expression, reflecting the importance of this portrait in conveying imperial dignity to the Roman public.<hr />
        <b><h4>Statujë mermeri e një djaloshi të ri gjendur pranë buleterionit</h4></b><small>(mendohet të jetë perandori Geta), 217-218 pas Kr.</small>
        <br/><br/>Kjo statujë mermeri, që paraqet një djalosh të ri dhe mendohet të jetë perandori Geta, është gjetur pranë buleterionit. 
        Daton nga viti 217-218 pas Krishtit, një periudhë kur portretet perandorake kishin për qëllim të theksonin autoritetin dhe statusin 
        e figurave të tyre. Statuja paraqet një mjeshtëri të lartë në punimin e draperive të rrobave dhe shprehjes së qetë të fytyrës, një 
        element që reflekton rëndësinë e këtij portreti për publikun romak.<br /><br /><br /><img src="ARKEOLOGJI_LOGO.png" width="200"/>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
    </div>
    </div>
    </div>
        )}

{/* Modal for modalObjekt5 */}
{activeModal === 'modalObjekt5' && (
    <div className="modal-overlay" tabIndex="-1" ref={modalRef} onClick={handleModalClick}>
            <div className="modal-content">
    <div className="modal-body">
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <button type="button" className="btn-close  close-button" onClick={closeModal} aria-label="Close"></button></div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-6 object3d"><small style={{background:'red'}} onClick={closeModal}>Press ESC to control object rotation</small>
          <iframe title=""
            allowFullScreen
            mozAllowFullScreen="true"
            webkitAllowFullScreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            width="100%"
            height="100%"
            src="https://sketchfab.com/models/3828ad97a7e84d7196d60b13fc945e15/embed?autostart=1"> 
          </iframe>
        </div>
        <div className="col-md-3 object-description" style={{ maxHeight: '650px', overflowY: 'scroll' }}>
        
        <b><h4>RELIEF WITH BANQUET SCENE</h4></b> <small>3rd century  BC, Apollonia, Stone, 82 x 27 x 42 cm, Native culture</small><br/><br/>
        From the 4th century BC, a rich figurative art emerged, reflecting scenes from the daily life of the Illyrians, such as entertainment scenes,
         like this relief. This fragment is part of a pediment, depicting a banquet scene with musicians, tables, and other festive elements, 
         showcasing the Illyrians' culture of joy and celebration. The carving demonstrates careful attention to the details of the garments 
         and the positioning of the figures, particularly the musician holding a lyre, a symbol of music and merriment.        
        <hr />
        <b><h4>RELIEV ME SKENË BANKETI</h4></b> <small>Shek. III para Kr., Apoloni, Gur, 82 x 27 x 42 cm, Kultura vendase</small><br/>
        Nga shekulli IV para Krishtit, u zhvillua një art figurativ i pasur, që pasqyronte momente nga jeta e përditshme e ilirëve, si skena 
        argëtimesh, siç është ky reliev. Ky fragment është pjesë e një frontoni, ku janë paraqitur skena të një banketi, me instrumentistë dhe 
        detaje të tjera të festës, duke treguar kulturën e gëzimeve dhe argëtimit të ilirëve. Gdhendja tregon kujdes të veçantë në detajet e 
        veshjeve dhe pozicionimin e figurave, veçanërisht në paraqitjen e instrumentistit, i cili mban një lirë në dorë, simbol i muzikës 
        dhe gëzimit.        
        <br /><br /><br /><br /><img src="MUZEU_LOGO.png" width="200"/>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
    </div>
    </div>
    </div>
        )}
{/* Modal for modalObjekt6 */}
{activeModal === 'modalObjekt6' && (
    <div className="modal-overlay" tabIndex="-1" ref={modalRef} onClick={handleModalClick}>
            <div className="modal-content">
    <div className="modal-body">
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <button type="button" className="btn-close  close-button" onClick={closeModal} aria-label="Close"></button></div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-6 object3d"><small style={{background:'red'}} onClick={closeModal}>Press ESC to control object rotation</small>
          <iframe title=""
            allowFullScreen
            mozAllowFullScreen="true"
            webkitAllowFullScreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            width="100%"
            height="100%"
            src="https://sketchfab.com/models/e6ca119e895a4c67982c48e8857660fe/embed?autostart=1"> 
          </iframe>
        </div>
        <div className="col-md-3 object-description" style={{ maxHeight: '650px', overflowY: 'scroll' }}>        
        <b><h4>THE RELIEF OF AN ILLYRIAN WOMAN</h4></b> <small>1st century AD, Durrës, Limestone, 36 x 93 x 15 cm, Local culture</small><br/><br/>
        This rectangular stele depicts the figure of a woman in relief, wearing traditional Illyrian attire. The sculpture clearly reflects the 
        ethnographic clothing of Illyrian women, with a long dress and a vest styled with shoulder straps. The Latin inscription informs us that 
        this stele was made in honor of a deceased girl named Lepidia, represented in this funerary relief with a dignified and elegantposture. 
        The detailed carving of her clothing and stance highlights the skill of the sculptor and the care taken to honor the deceased.<hr />
        <b><h4>RELIEVI NJË GRUAJE ILIRE</h4></b><small>Shek. I pas Kr., Durrës, Gur gëlqeror, 36 x 93 x 15 cm, Kultura vendase</small><br/><br/>
        Kjo stelë katërkëndëshe paraqet në reliev figurën e një gruaje me veshje tipike ilire. Skulptura tregon qartë veshjen etnografike të 
        grave ilire, me një fustan të gjatë dhe një jelek të zbukuruar me shirita në supe. Mbishkrimi në latinisht na mëson se kjo stelë është 
        krijuar për nder të vajzës së vdekur me emrin Lepidia, e përfaqësuar në këtë paraqitje funerare me një qëndrim dinjitoz dhe elegant. 
        Detajet e qarta të veshjes dhe qëndrimit tregojnë mjeshtërinë e gdhendësit dhe kujdesin për të nderuar të ndjerën.
        <br /><br /><br /><img src="MUZEU_LOGO.png" width="200"/>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
    </div>
    </div>
    </div>
        )}

{/* Modal for modalObjekt7 */}
{activeModal === 'modalObjekt7' && (
    <div className="modal-overlay" tabIndex="-1" ref={modalRef} onClick={handleModalClick}>
            <div className="modal-content">
    <div className="modal-body">
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <button type="button" className="btn-close  close-button" onClick={closeModal} aria-label="Close"></button></div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-6 object3d"><small style={{background:'red'}} onClick={closeModal}>Press ESC to control object rotation</small>
        <iframe title=""
            allowFullScreen
            mozAllowFullScreen="true"
            webkitAllowFullScreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            width="100%"
            height="100%"
            src="https://sketchfab.com/models/6bb62cc4a17c4ef4bc6b33225adc76ad/embed?autostart=1"> 
          </iframe>
        </div>
        <div className="col-md-3 object-description" style={{ maxHeight: '650px', overflowY: 'scroll' }}>
        <b><h4>CAPITAL</h4></b><small>3rd centrz BC, Apollonia, Limestone, 47x34,5 cm, Corinthian culture</small><br/><br/>
        A Corinthian capital crafted from white limestone, well-preserved. The Corinthian order, the latest and most developed in 
        classical architecture, is characterized by slender columns with flutes and capitals decorated with acanthus leaves below the volutes.<hr/>
        <b><h4>KAPITEL</h4></b><small>Shek. III para Kr., Apoloni, Gur gëlqeror, 47x34.5, Kultura korintike</small><br/><br/>
        Kapitel korintik, punuar me gur gëlqeror të bardhë, i ruajtur mirë. Orderi korintik, më i vonë dhe i zhvilluar në arkitekturën klasike, 
        dallohet nga kolonat e holla me kanelyra dhe kapitelet e zbukuruara me gjethe dashtre poshtë spiraleve.
        <br /><br /><br /><br /><img src="MUZEU_LOGO.png" width="200"/></div>
        <div className="col-md-1"></div>
      </div>
    </div>
    </div>
    </div>
    </div>
        )}

{/* Modal for modalObjekt9 */}
{activeModal === 'modalObjekt9' && (
    <div className="modal-overlay" tabIndex="-1" ref={modalRef} onClick={handleModalClick}>
            <div className="modal-content">
    <div className="modal-body">
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <button type="button" className="btn-close  close-button" onClick={closeModal} aria-label="Close"></button></div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-6 object3d"><small style={{background:'red'}} onClick={closeModal}>Press ESC to control object rotation</small>
          <iframe title=""
            allowFullScreen
            mozAllowFullScreen="true"
            webkitAllowFullScreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            width="100%"
            height="100%"
            src="https://sketchfab.com/models/985c48ba73b0411e80738e69dd3a1828/embed?autostart=1"> 
          </iframe>
        </div>
        <div className="col-md-3 object-description" style={{ maxHeight: '650px', overflowY: 'scroll' }}>
        <b><h4>PORTRAIT OF A GENTLEMAN FROM APOLONIA </h4></b> <small>Apolonia, 2nd century AD</small><br/><br/>This marble bust 
        represents an aristocrat from Apollonia and dates to the 2nd century AD. The style is typical of the Roman period, with a 
        focus on realistic facial features and hair, highlighting the subject’s status and strength. The craftsmanship reflects the 
        strong influence of Roman art in Apollonia, an important cultural and political center in Illyria.<hr />
        <b><h4>PORTRET I NJË ARISTOKRATI NGA APOLLONIA</h4></b> <small>Apolloni, Shek. II pas Kr.</small><br/><br/>Ky bust mermeri përfaqëson 
        një aristokrat nga Apollonia dhe daton në shekullin II pas Krishtit. Stili i portretit është karakteristik i periudhës romake, 
        me fokus në detajet realiste të fytyrës dhe flokëve, duke nxjerrë në pah statusin dhe forcën e subjektit. Mjeshtëria e punimit 
        tregon për influencën e madhe të artit romak në qytetin e Apollonisë, një qendër e rëndësishme kulturore dhe politike në Iliri.
        <br /><br /><br /><br /><img src="MUZEU_LOGO.png" width="200"/>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
    </div>
    </div>
    </div>
        )}
{activeModal === 'modalObjekt8' && (
    <div className="modal-overlay" tabIndex="-1" ref={modalRef} onClick={handleModalClick}>
            <div className="modal-content">
    <div className="modal-body">
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <button type="button" className="btn-close  close-button" onClick={closeModal} aria-label="Close"></button></div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-6 object3d"><small style={{background:'red'}} onClick={closeModal}>Press ESC to control object rotation</small>
          <iframe title=""
            allowFullScreen
            mozAllowFullScreen="true"
            webkitAllowFullScreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            width="100%"
            height="100%"
            src="https://sketchfab.com/models/285d2470f05b4b2fa84ca77e4b70f7f0/embed?autostart=1"> 
          </iframe>
        </div>
        <div className="col-md-3 object-description" style={{ maxHeight: '650px', overflowY: 'scroll' }}>
        <b><h4>STELE</h4></b> <small>1st-2nd century AD, Terpan (Berat), Marble, 46 x 28 x 9 cm, Roman culture</small><br/><br/>
        The stele depicts a man dressed in Illyrian attire, with his name and farewell formula inscribed in ancient Greek. The sculpture 
        has damage to the head and part of the face, but the stance and the remaining details of the clothing and weaponry are still clear. 
        The intricate carving of the helmet and armor showcases skilled craftsmanship, emphasizing the importance of Illyrian warriors.<hr />
        <b><h4>STELË</h4></b> <small>Shek. I-II pas Kr., Tërpan (Berat), Mermer, 46 x 28 x 9 cm, Kultura romake</small><br/><br/>Stela paraqet 
        një burrë të veshur me kostum ilir, ndërsa emri i tij dhe formula e lamtumirës janë të shkruara në greqishten e vjetër. Skulptura është 
        e dëmtuar në kokë dhe një pjesë të fytyrës, por mbetet e qartë për nga qëndrimi dhe detajet e mbetura të veshjeve dhe armëve. Gdhendja 
        e detajeve të përkrenares dhe armaturës tregon një mjeshtëri të madhe artistike dhe përpjekjen për të paraqitur rëndësinë e luftëtarëve ilirë.
        <br /><br /><br /><br /><img src="MUZEU_LOGO.png" width="200"/>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
    </div>
    </div>
    </div>
        )}
{activeModal === 'modalObjekt10' && (
    <div className="modal-overlay" tabIndex="-1" ref={modalRef} onClick={handleModalClick}>
            <div className="modal-content">
    <div className="modal-body">
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <button type="button" className="btn-close  close-button" onClick={closeModal} aria-label="Close"></button></div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-6 object3d"><small style={{background:'red'}} onClick={closeModal}>Press ESC to control object rotation</small>
          <iframe title=""
            allowFullScreen
            mozAllowFullScreen="true"
            webkitAllowFullScreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            width="100%"
            height="100%"
            src="https://sketchfab.com/models/a2675cfdfc0b484ead942c7d786e98bc/embed?autostart=1"> 
          </iframe>
        </div>
        <div className="col-md-3 object-description" style={{ maxHeight: '650px', overflowY: 'scroll' }}>
        <b><h4>GRAVE STELE </h4></b> <small>3rd century BC, Cakran (Fier), Limestone, 40 x 61 cm, Hellenistic culture</small><br/><br/>
          The relief depicts a familiar Hellenistic scene of the time:  two women visiting the graves of their deceased relatives, 
          represented as two herms. Dressed in classical garments, the women appear engaged in conversation or quietly expressing grief. 
          The craftsmanship is detailed, with clear attention given to the folds of the garments, which are finely rendered to create a
          naturalistic sense of flow and movement. 
         <hr />
         <b><h4>STELË VARRI</h4></b> <small>Shek. III para Kr, Cakran (Fier), Gur gëlqeror, 40 x 61 cm, Kultura helenistike</small><br/><br/>
         Relievi paraqet një skenë të njohur nga arti helenistik i kësaj kohe: dy gra vizitojnë varret e të afërmve të tyre, të cilët 
         përfaqësohen si hermesa. Të veshura me rroba klasike, gratë duket sikur janë duke biseduar ose po shprehin hidhërim. Gdhendja 
         e bërë është e detajuar dhe i është kushtuar vëmendje detajeve të rrobave duke u dhënë një ndjesi natyrale të lëvizjes.
        <br /><br /><br /><img src="MUZEU_LOGO.png" width="200"/>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
    </div>
    </div>
    </div>
        )}

{activeModal === 'modalObjekt11' && (
    <div className="modal-overlay" tabIndex="-1" ref={modalRef} onClick={handleModalClick}>
            <div className="modal-content">
    <div className="modal-body">
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <button type="button" className="btn-close  close-button" onClick={closeModal} aria-label="Close"></button></div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-6 object3d"><small style={{background:'red'}} onClick={closeModal}>Press ESC to control object rotation</small>
          <iframe title=""
            allowFullScreen
            mozAllowFullScreen="true"
            webkitAllowFullScreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            width="100%"
            height="100%"
            src="https://sketchfab.com/models/d2c4b0bb8a9640ccb16acba56fa6286c/embed?autostart=1"> 
          </iframe>
        </div>
        <div className="col-md-3 object-description" style={{ maxHeight: '650px', overflowY: 'scroll' }}>
          <b><h4>THREE-STORIED GRAVE STELE</h4></b><small>3rd century BC, Apollonia (Fier), Stone, 223.5 x 57 x 20 cm, Hellenistic 
          culture</small><br />Sepulchral reliefs are widely represented in Apollonia, and this Illyrian funerary stele is a unique example. 
          The vertical steles, divided into two or three planes, offer a distinctive compositional solution. This particular stele, belonging 
          to an Illyrian family, is divided into three levels, each depicting the final farewell across generations. The uppermost level portrays 
          a lively scene with multiple figures, while the second level shows a more formal farewell. The craftsmanship is evident in the fine
          details of the garments and the postures of the figures, showcasing the sculptor’s ability to convey solemnity. The lower section 
          contains meticulously carved figures, enhancing the dignified and grandiose message of the memorial.<hr />
          <b><h4>STELË VARRI ME 
          TRE KATE</h4></b><small>Shek. III para Kr., Apoloni, Gur, 223.5 x 57 x 20 cm, Kultura helenistike</small><br /><br />
          Relievi sepulkral është i përfaqësuar gjerësisht në Apoloni dhe kjo stelë mortore ilire përfaqëson një shembull të veçantë. 
          Stelat vertikale, të ndara në dy ose tri plane, janë një zgjidhje unike kompozicionale. Kjo stelë e një familjeje ilire është
          ndarë në tri nivele, duke treguar lamtumirën e fundit në secilin brez. Në nivelin e sipërm paraqitet një skenë e gjallë e
          personazheve, ndërsa niveli i dytë tregon një skenë më formale të ndarjes. Detajet e gdhendura, sidomos në veshjet e 
          figurave dhe pozat e tyre, tregojnë një mjeshtëri të lartë të skulptorit, që ka arritur të japë një ndjenjë solemniteti.
          Pjesa e poshtme përmban figura të skalitura me kujdes, duke përforcuar mesazhin mortor me dinjitetin dhe madhështinë e saj.
          <br /><br /><br /><img src="MUZEU_LOGO.png" width="200"/>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
    </div>
    </div>
    </div>
        )}
   {/* Modal for modalObjekt12 */}
{activeModal === 'modalObjekt12' && (
    <div className="modal-overlay" tabIndex="-1" ref={modalRef} onClick={handleModalClick}>
            <div className="modal-content">
    <div className="modal-body">
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <button type="button" className="btn-close  close-button" onClick={closeModal} aria-label="Close"></button></div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-6 object3d"><small style={{background:'red'}} onClick={closeModal}>Press ESC to control object rotation</small>
          <iframe title=""
            allowFullScreen
            mozAllowFullScreen="true"
            webkitAllowFullScreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            width="100%"
            height="100%"
            src="https://sketchfab.com/models/03f5d9f8c4c24af580d8bc14bce83322/embed?autostart=1"> 
          </iframe>
        </div>
        <div className="col-md-3 object-description" style={{ maxHeight: '650px', overflowY: 'scroll' }}>
          <b><h4>Personification of Vjosa River</h4></b><small>3rd century BC, Apollonia, Marble, 49 x 70 cm, Local culture</small><br />
          This sculpture is dedicated to the River Vjosa, personified as an elderly man reclining and resting on his right arm. 
          His thick hair and beard flow down to his shoulders, conveying a sense of grandeur and power. This anthropomorphic depiction
          highlights the prosperity of the river and the fertility of the valleys nearby. The sculptor chose a symbolic approach to
          represent the waves of the Vjosa River by elongating the limbs in a wave-like form, without emphasizing detailed anatomy. 
          This representation merges nature and divinity, symbolizing the strength and continuity of the river. <hr />
          <b><h4>PERSONIFIKIMI I LUMIT VJOSA</h4></b><small>Shek. III para Kr., Apoloni, Mermer, 49 x 70 cm, Kultura vendase</small><br /><br />
          Kjo skulpturë i kushtohet lumit Vjosa, i cili është personifikuar si një plak i shtrirë, mbështetur në krahun e djathtë. 
          Flokët dhe mjekra e tij e dendur shtrihen deri tek supet, duke dhënë një ndjenjë madhështie dhe fuqie. Paraqitja 
          antropomorfe tregon begatinë e lumit dhe pjellorinë e luginave pranë tij. Skulptori ka zgjedhur një qasje simbolike 
          për të përfaqësuar dallgët e lumit Vjosa, duke paraqitur gjymtyrët e zgjatura në formë valësh, pa detaje anatomike të theksuara.
          Ky përshkrim ndërthur natyrën dhe hyjnoren, duke simbolizuar forcën dhe vazhdimësinë e lumit.
          <br /><br /><br /><br /><img src="MUZEU_LOGO.png" width="200"/>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
    </div>
    </div>
    </div>
        )}
 {activeModal === 'modalObjekt13' && (
    <div className="modal-overlay" tabIndex="-1" ref={modalRef} onClick={handleModalClick}>
            <div className="modal-content">
    <div className="modal-body">
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <button type="button" className="btn-close  close-button" onClick={closeModal} aria-label="Close"></button></div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-6 object3d"><small style={{background:'red'}} onClick={closeModal}>Press ESC to control object rotation</small>
          <iframe title=""
            allowFullScreen
            mozAllowFullScreen="true"
            webkitAllowFullScreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            width="100%"
            height="100%"
            src="https://sketchfab.com/models/000d2484c37748be95ae2c2bc5346e70/embed?autostart=1"> 
          </iframe>
        </div>
        <div className="col-md-3 object-description" style={{ maxHeight: '650px', overflowY: 'scroll' }}>
        <b><h4>EROS SPANNING THE BOW</h4></b> 
        <small>Marble sculpture found in the Roman villa of Qesarat, near Bylis, 2nd century AD</small><br/><br/>
        This marble sculpture, depicting Eros in the act of aiming his arrow, is a delicate and lively piece. 
        Found in the Roman villa of Qesarat near Bylis, the sculpture dates back to the 2nd century AD. Eros 
        is shown in a dynamic pose, with his body bent and arms poised to shoot his symbolic arrow. The 
        craftsmanship in the detailing, especially in the curly hair and the soft contours of the body, 
        reflects the high skill of the sculptor and the deep influence of Roman art in the region. This 
        piece not only showcases the aesthetic beauty of sculptures from this period but also highlights 
        the significance of mythology in Roman life and art.<hr/>
        <b><h4>EROSI DUKE VËRTITUR SHIGJETËN</h4></b>
        <small>Skulpturë mermeri gjetur në vilën Romake të Qesaratit, prane Bylis, Shek. II pas Kr.</small><br/><br/>
        Kjo skulpturë mermeri, që përfaqëson Erosin në momentin e hedhjes së shigjetës, është një vepër delikate dhe plot gjallëri. 
        Gjetur në vilën romake të Qesaratit, pranë Bylis, skulptura daton nga shekulli II pas Krishtit. Erosi është paraqitur në një 
        pozicion dinamik, me trupin e përkulur dhe krahët gati për të hedhur shigjetën e tij simbolike. Mjeshtëria në gdhendjen e detajeve, 
        sidomos në trajtimin e flokëve kaçurrelë dhe linjave të buta të trupit, pasqyron aftësitë e larta të skulptorit dhe ndikimin e 
        thellë të artit romak në rajon. Kjo vepër tregon jo vetëm bukurinë estetike të skulpturave të periudhës, por edhe rëndësinë e 
        mitologjisë në jetën dhe artin e Romës antike.
        <br /><br /><br /><img src="APOLLONI_LOGO.png" width="200"/>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
    </div>
    </div>
    </div>
        )}

{/* Modal for modalObjekt14 */}
{activeModal === 'modalObjekt14' && (
    <div className="modal-overlay" tabIndex="-1" ref={modalRef} onClick={handleModalClick}>
            <div className="modal-content">
    <div className="modal-body">
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <button type="button" className="btn-close  close-button" onClick={closeModal} aria-label="Close"></button></div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-6 object3d"><small style={{background:'red'}} onClick={closeModal}>Press ESC to control object rotation</small>
          <iframe title=""
            allowFullScreen
            mozAllowFullScreen="true"
            webkitAllowFullScreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            width="100%"
            height="100%"
            src="https://sketchfab.com/models/eeb52f66d10f4f1d96b96e9639278ced/embed?autostart=1"> 
          </iframe>
        </div>
        <div className="col-md-3 object-description" style={{ maxHeight: '650px', overflowY: 'scroll' }}>
        <b><h4>STATURE OF A SOLDIER</h4></b> <small>1st century AD, Butrint (Saranda), Marble, 180 x 70 x 85 cm, Local culture</small><br/><br/>
        This marble sculpture represents the body of a soldier, missing its head. In his hand, he holds a chlamys, a cloak that Roman cavalrymen 
        would drape over their shoulders and fasten at the neck. Such military sculptures were a way to immortalize the image of power and the 
        strength of the Roman army, particularly during the reigns of emperors who elevated the soldier to a highly symbolic status.<hr />
        <b><h4>SHTATI I NJË USHTARAKU</h4></b> <small>Shek. I pas Kr., Butrint (Sarandë), Mermer, 180 x 70 x 85 cm, Kultura vendase</small><br/><br/>
        Kjo skulpturë prej mermeri përfaqëson trupin e një ushtaraku, të cilit i mungon koka. Në dorën e tij mban një klamidë, një pelerinë që 
        kalorësit romakë e hidhnin mbi supe dhe e mbërthenin në qafë. Skulpturat e tilla ushtarake ishin një mënyrë për të përjetësuar imazhin e 
        pushtetit dhe forcës së ushtrisë romake, veçanërisht në kohën e perandorëve që e ngritën ushtarin dhe figurën e tij në një nivel të lartë 
        simbolik.<br /><br /><br /><img src="MUZEU_LOGO.png" width="200"/>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
    </div>
    </div>
    </div>
        )}


{/* Modal for modalObjekt0001 */}
{activeModal === 'modalObjekt0001' && (
    <div className="modal-overlay" tabIndex="-1" ref={modalRef} onClick={handleModalClick}>
            <div className="modal-content">
    <div className="modal-body">
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <button type="button" className="btn-close  close-button" onClick={closeModal} aria-label="Close"></button></div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-6 object3d"><small style={{background:'red'}} onClick={closeModal}>Press ESC to control object rotation</small>
          <iframe title=""
            allowFullScreen
            mozAllowFullScreen="true"
            webkitAllowFullScreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            width="100%"
            height="100%"
            src="https://sketchfab.com/models/361a1cd301b7405a9a04c290cf30e978/embed?autostart=1"> 
          </iframe>
        </div>
        <div className="col-md-3 object-description" style={{ maxHeight: '650px', overflowY: 'scroll' }}>
        <b><h4>EROS HOLDING A DOLPHIN</h4></b> 
        <small>Bronze figurine installed in a fountain, 1st century AD</small><br/><br/>
        This bronze figurine, depicts Eros holding a dolphin. A decorative piece from a Roman fountain, it highlights the 
        influence of Roman art and craftsmanship in the region.<hr />
        <b><h4>EROSI DUKE MBAJTUR NJË DELFIN </h4></b> 
        <small>Figurinë punuar në bronz pjesë e një shatërrvani, Shek. I pas Kr.</small><br/><br/>
        Kjo figurë bronzi,paraqet Erosin duke mbajtur një delfin. Pjesë dekorative e një shatërvani romak, ajo dëshmon për ndikimin 
        e artit dhe mjeshtërisë romake në rajon.
        <br /><br /><br /><img src="MUZEU_LOGO.png" width="200"/>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
    </div>
    </div>
    </div>
        )}
{/* Modal for modalObjekt0002 */}
{activeModal === 'modalObjekt0002' && (
    <div className="modal-overlay" tabIndex="-1" ref={modalRef} onClick={handleModalClick}>
            <div className="modal-content">
    <div className="modal-body">
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <button type="button" className="btn-close  close-button" onClick={closeModal} aria-label="Close"></button></div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-6 object3d"><small style={{background:'red'}} onClick={closeModal}>Press ESC to control object rotation</small>
          <iframe title=""
            allowFullScreen
            mozAllowFullScreen="true"
            webkitAllowFullScreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            width="100%"
            height="100%"
            src="https://sketchfab.com/models/8e4aa52944864540b01c93335b2aa359/embed?autostart=1"> 
          </iframe>
        </div>
        <div className="col-md-3 object-description" style={{ maxHeight: '650px', overflowY: 'scroll' }}>
        <b><h4>FOOT OF A BRONZE VASE</h4></b> 
        <small>3rd century BC.</small>
        This bronze object, part of a 3rd-century BC vase, is carved in the form of a sphinx. The detailed 
        carving reflects Hellenistic art's influence and the craftsmanship of bronze work in the region, 
        highlighting the role of Greek mythology in everyday decorative objects.<hr />
        <b><h4>KËMBË VAZOJE </h4></b> 
        <small>Shek. III para Kr.</small><br/><br/>
        Ky objekt bronzi, pjesë e një vazoje nga shekulli III para Krishtit, është gdhendur në formën e një sfinksi. 
        Gdhendja e detajuar pasqyron ndikimin e artit helenistik dhe mjeshtërinë e punimit të bronzit në rajon, duke 
        treguar për rëndësinë e mitologjisë greke në zbukurimet e përditshme.
        <br /><br /><br /><img src="MUZEU_LOGO.png" width="200"/>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
    </div>
    </div>
    </div>
        )}

{activeModal === 'modalObjekt0003' && (
    <div className="modal-overlay" tabIndex="-1" ref={modalRef} onClick={handleModalClick}>
            <div className="modal-content">
    <div className="modal-body">
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <button type="button" className="btn-close  close-button" onClick={closeModal} aria-label="Close"></button></div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-6 object3d"><small style={{background:'red'}} onClick={closeModal}>Press ESC to control object rotation</small>
          <iframe title=""
            allowFullScreen
            mozAllowFullScreen="true"
            webkitAllowFullScreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            width="100%"
            height="100%"
            src="https://sketchfab.com/models/9ef433c7229648d98594b52a6b34b122/embed?autostart=1"> 
          </iframe>
        </div>
        <div className="col-md-3 object-description" style={{ maxHeight: '650px', overflowY: 'scroll' }}>
        <b><h4>Poseidon</h4></b> 
        <small>Bronze Figurine, 3rd Century BC, Archaeological Park of Antigonea</small><br/><br/>
          This bronze figurine of Poseidon, discovered in the Archaeological Park of Antigonea, dates to the 3rd century BC. 
          Represented in a dynamic pose, the god of the sea stands with one arm raised, symbolizing his power over the waters. 
          The detailed craftsmanship reflects the influence of Greek art in the Illyrian region, where local artisans embraced 
          and adapted Hellenistic styles, highlighting the cultural exchange between the two civilizations.
        <hr />
        <b><h4>Poseidoni</h4></b><small>Figurinë Bronzi, Shek. III para Kr.Parku Arkeologjik i Antigonës</small><br/><br/>
        Kjo figurinë bronzi e Poseidonit, zbuluar në Parkun Arkeologjik të Antigonës, daton nga shekulli III para Krishtit. 
          I paraqitur në një pozë dinamike, zoti i deteve qëndron me një dorë të ngritur, duke simbolizuar fuqinë e tij mbi ujërat. 
          Mjeshtëria e përpunimit reflekton ndikimin e artit grek në rajonin ilir, ku artizanët vendas përvetësuan dhe përshtatën 
          stilet helenistike, duke theksuar shkëmbimin kulturor midis dy qytetërimeve.
          <br /><br /><br /><img src="MUZEU_LOGO.png" width="200"/>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
    </div>
    </div>
    </div>
        )}
{activeModal === 'modalObjekt0004' && (
    <div className="modal-overlay" tabIndex="-1" ref={modalRef} onClick={handleModalClick}>
            <div className="modal-content">
    <div className="modal-body">
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <button type="button" className="btn-close  close-button" onClick={closeModal} aria-label="Close"></button></div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-6 object3d"><small style={{background:'red'}} onClick={closeModal}>Press ESC to control object rotation</small>
          <iframe title=""
            allowFullScreen
            mozAllowFullScreen="true"
            webkitAllowFullScreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            width="100%"
            height="100%"
            src="https://sketchfab.com/models/1d60eb7330d24398bd6a8c9a012fe6ce/embed?autostart=1"> 
          </iframe>
        </div>
        <div className="col-md-3 object-description" style={{ maxHeight: '650px', overflowY: 'scroll' }}>
        <b><h4>Athlete</h4></b> 
        <small>Bronze Figurine, 480 BC, Amantia</small><br/><br/>
        This bronze figurine of an athlete, dating to 480 BC, was discovered in Amantia. Depicted in a dynamic pose, the figure represents the
         idealized physical form and strength celebrated in Greek art. The detailed anatomy and movement reflect the Greek artistic influence 
         on Illyrian culture, where athletic competitions and the portrayal of the human body were highly valued.

        <hr />
        <b><h4>Atlet</h4></b> 
        <small>Figurinë Bronz, 480 para Kr., Amantia</small><br/><br/>
        Kjo figurinë bronzi e një atleti, që daton nga viti 480 para Krishtit, u zbulua në Amantia. E paraqitur në një pozë dinamike, 
        figura përfaqëson formën fizike dhe forcën e idealizuar që vlerësohej në artin grek. Anatomia e detajuar dhe lëvizja reflektojnë
         ndikimin e artit grek në kulturën ilire, ku garat atletike dhe portretizimi i trupit njerëzor kishin rëndësi të veçantë.

        <br /><br /><br /><img src="MUZEU_LOGO.png" width="200"/>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
    </div>
    </div>
    </div>
        )}        
{/* Modal for modalObjekt16 */}
{activeModal === 'modalObjekt16' && (
    <div className="modal-overlay" tabIndex="-1" ref={modalRef} onClick={handleModalClick}>
            <div className="modal-content">
    <div className="modal-body">
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <button type="button" className="btn-close  close-button" onClick={closeModal} aria-label="Close"></button></div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-6 object3d"><small style={{background:'red'}} onClick={closeModal}>Press ESC to control object rotation</small>
          <iframe title=""
            allowFullScreen
            mozAllowFullScreen="true"
            webkitAllowFullScreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            width="100%"
            height="100%"
            src="https://sketchfab.com/models/aa7acf6fba934b4fbfd88d44b1c3a574/embed?autostart=1"> 
          </iframe>
        </div>
        <div className="col-md-3 object-description" style={{ maxHeight: '650px', overflowY: 'scroll' }}>
        <b><h4>STATURE OF WOMAN</h4></b><small>3rd - 2nd century BC, Vlora</small><br/><br/>
        The sculpture depicts a woman draped in a chiton and a himation, with the folds of the cloak 
        clinging to her body in certain areas. Her calm stance, with the left leg bearing weight and 
        the right leg slightly bent, showcases the sculptor's skill in rendering natural posture and 
        movement. The intricate folds of the garments are meticulously carved, adding a sense of realism 
        and depth to the figure. The exposed hand holds a corner of the himation, while the left arm 
        supports a diptych, reinforcing the figure’s dignified stance. Despite the loss of the head 
        and damage to the fingers, the sculpture maintains its aesthetic integrity, reflecting the 
        sculptor’s ability to convey elegance and grandeur.<hr />
        <b><h4>SHTATI I NJË GRUAJE</h4></b><small>Shek. I pas Kr., Apoloni, Mermer, 180 x 76 x 44 cm, 
        Kultura vendase</small><br/><br/>
        Shtat gruaje e veshur me klamidë (pelerinë që hidhej në krahë dhe mbërthehej në grykë). Asaj i mungon koka e cila është e punuar më vete. 
        Ky shtat e ka pjesën e qafës të punuar nga ku mendohet se për shkak të mjeteve primitive që kishte skulptori, i nevojitej shumë kohë për 
        ta përfunduar një trup të tërë. Për këtë arsye, ai krijonte një trup dhe më pas e ndërronte kokën sipas dëshirës.
         <br /><br /><br /><img src="MUZEU_LOGO.png" width="200"/>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
    </div>
    </div>
    </div>
        )}
{/* Modal for modalObjekt17 */}
{activeModal === 'modalObjekt17' && (
    <div className="modal-overlay" tabIndex="-1" ref={modalRef} onClick={handleModalClick}>
            <div className="modal-content">
    <div className="modal-body">
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <button type="button" className="btn-close  close-button" onClick={closeModal} aria-label="Close"></button></div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-6 object3d"><small style={{background:'red'}} onClick={closeModal}>Press ESC to control object rotation</small>
          <iframe title=""
            allowFullScreen
            mozAllowFullScreen="true"
            webkitAllowFullScreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            width="100%"
            height="100%"
            src="https://sketchfab.com/models/1cd917d40b9544769431ff9b44e21c6e/embed?autostart=1"> 
          </iframe>
        </div>
        <div className="col-md-3 object-description" style={{ maxHeight: '650px', overflowY: 'scroll' }}>
        <b><h4>Portrait of a man from Jul-Claudian imperial family</h4></b><small>1st century AD</small><br /><br />
        This marble sculpture depicts a man from the Julio-Claudian imperial family, an influential dynasty in the Roman Empire. 
        Dating to the 1st century AD, it reflects Roman portraiture's focus on realism, particularly in the detailed facial features. The craftsmanship highlights the precision in capturing the subject’s features and emphasizing the imperial status.<hr /><b><h4>Portret burri nga familja perandorake Jul-Klaudian</h4></b><small>Shek I pas Kr.</small><br /><br />Kjo skulpturë mermeri paraqet një burrë nga familja perandorake Jul-Klaudian, një dinasti e rëndësishme në Perandorinë Romake. Daton nga shekulli I pas Krishtit dhe tregon ndikimin romak në artin e portretistikës, duke theksuar realizmin në detajet e fytyrës. Mjeshtëria e skulptorit vërehet në kapjen e tipareve të sakta dhe në theksimin e statusit perandorak.<br /><br /><br /><br /><img src="ARKEOLOGJI_LOGO.png" width="200"/>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
    </div>
    </div>
    </div>
        )}

{/* Modal for modalObjekt18 */}
{activeModal === 'modalObjekt18' && (
    <div className="modal-overlay" tabIndex="-1" ref={modalRef} onClick={handleModalClick}>
            <div className="modal-content">
    <div className="modal-body">
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <button type="button" className="btn-close  close-button" onClick={closeModal} aria-label="Close"></button></div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-6 object3d"><small style={{background:'red'}} onClick={closeModal}>Press ESC to control object rotation</small>
          <iframe title=""
            allowFullScreen
            mozAllowFullScreen="true"
            webkitAllowFullScreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            width="100%"
            height="100%"
            src="https://sketchfab.com/models/937405df47fe438280d3f97921fa14c6/embed?autostart=1"> 
          </iframe>
        </div>
        <div className="col-md-3 object-description" style={{ maxHeight: '650px', overflowY: 'scroll' }}>
        <b><h4>THE HEAD OF ARTEMIS</h4></b> 
        <small>3rd century BC</small><br/><br/>
        This marble head of the goddess Artemis was found near the Temple of Diana and dates to the 3rd century BC. 
        The soft features and distinctive hairstyle, tied up high, are key identifiers of her as the goddess of the hunt. 
        This discovery highlights the importance of Artemis' cult in the region and the influence of Hellenistic art.<hr />
		    <b><h4>KOKA E ARTEMISËS</h4></b> 
        <small>Shek. III para Kr.</small><br/><br/>
        Kjo kokë mermeri e perëndeshës Artemis është zbulua pranë tempullit të Dianës dhe daton nga shekulli III para Krishtit. Tiparet e buta dhe stili karakteristik i flokëve, të mbledhura lart, janë tregues të identitetit të saj si hyjneshë e gjuetisë. Ky zbulim tregon rëndësinë e kultit të Artemisës në rajon dhe ndikimin e artit helenistik.
        <br /><br /><br /><img src="MUZEU_LOGO.png" width="200"/>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
    </div>
    </div>
    </div>
        )}


 
{/* Modal for modalObjekt19 */}
{activeModal === 'modalObjekt19' && (
    <div className="modal-overlay" tabIndex="-1" ref={modalRef} onClick={handleModalClick}>
            <div className="modal-content">
    <div className="modal-body">
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <button type="button" className="btn-close  close-button" onClick={closeModal} aria-label="Close"></button></div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-6 object3d"><small style={{background:'red'}} onClick={closeModal}>Press ESC to control object rotation</small>
          <iframe title=""
            allowFullScreen
            mozAllowFullScreen="true"
            webkitAllowFullScreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            width="100%"
            height="100%"
            src="https://sketchfab.com/models/eb28a595a0be4379bb933f2daaa0c489/embed?autostart=1"> 
          </iframe>
        </div>
        <div className="col-md-3 object-description" style={{ maxHeight: '650px', overflowY: 'scroll' }}>
        <h4><b>PORTRAIT OF A MAN</b></h4><small>3rd century AD</small><br /><br />
        This marble sculpture portrays an important figure from the Severan dynasty, which ruled in the 3rd century AD. 
        The art of this period was characterized by deep realism, where portraits emphasized individual features to convey 
        authority and power. The Roman influence is evident in the detailed rendering of the beard and hair, highlighting 
        the importance of individualized portraiture during this era.<hr />
        <h4><b>PORTRET BURRI</b></h4><small>Shek. III pas Kr.</small><br /><br />Kjo skulpturë mermeri paraqet një figurë të 
        rëndësishme nga dinastia Severiane, që sundoi në shekullin III pas Krishtit. Artet e kësaj periudhe u dalluan për 
        realizmin e thellë, ku portretet shfaqnin tiparet e veçanta të subjekteve për të theksuar autoritetin dhe pushtetin e tyre. 
        Stili romak është i dukshëm në përpunimin e detajuar të mjekrës dhe flokëve, duke theksuar rëndësinë e portretizimit 
        individual gjatë kësaj epoke.<br /><br /><br /><br /><img src="MUZEU_LOGO.png" width="200"/>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
    </div>
    </div>
    </div>
        )}

{/* Modal for modalObjekt20 */}
{activeModal === 'modalObjekt20' && (
    <div className="modal-overlay" tabIndex="-1" ref={modalRef} onClick={handleModalClick}>
            <div className="modal-content">
    <div className="modal-body">
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <button type="button" className="btn-close  close-button" onClick={closeModal} aria-label="Close"></button></div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-6 object3d"><small style={{background:'red'}} onClick={closeModal}>Press ESC to control object rotation</small>
          <iframe title=""
            allowFullScreen
            mozAllowFullScreen="true"
            webkitAllowFullScreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            width="100%"
            height="100%"
            src="https://sketchfab.com/models/f76bc8bed7ba41f88ce9876437897fd6/embed?autostart=1"> 
          </iframe>
        </div>
        <div className="col-md-3 object-description" style={{ maxHeight: '650px', overflowY: 'scroll' }}>
        <b><h4> Apollonia</h4></b><br />Apollonia, one of the most important archaeological parks in Albania, 
        was founded in the 6th century BC by Greek colonists from Corfu and Corinth. Located near the city of 
        Fier, in the southwestern part of Albania, Apollonia became a thriving city-state due to its strategic 
        position along the Via Egnatia, an ancient Roman road that linked the Adriatic Sea to Byzantium. The city 
        flourished under both Greek and Roman influence, with its peak during the 4th century BC when it was an important 
        economic and cultural center of the Mediterranean. Apollonia was known for its thriving trade, sophisticated urban 
        planning, and the famous school of philosophy.The park showcases a fascinating blend of ancient Greek and Roman architecture, 
        including a well-preserved theater, temples, the Bouleuterion (council house), and remains of a Roman forum. The influence of 
        Apollonia extended far beyond its borders, contributing to the spread of Greek culture throughout the Adriatic region. 
        The park’s untouched ruins offer a rare glimpse into a once-thriving city, making it a key site for understanding the 
        ancient history of the region and a treasure trove for archaeologists.<hr /><b><h4> Apollonia</h4></b><br />Apollonia, 
        një nga parqet arkeologjike më të rëndësishme në Shqipëri, u themelua në shekullin e 6 p.e.s nga kolonë grekë nga Korfuzi dhe Korinti. 
        E vendosur pranë qytetit të Fierit, në pjesën jugperëndimore të Shqipërisë, Apollonia u bë një qytet-shtet i lulëzuar për shkak të 
        pozicionit të saj strategjik përgjatë Via Egnatia-s, një rrugë e lashtë romake që lidhte Detin Adriatik me Bizantin. Qyteti lulëzoi 
        nën ndikimin grek dhe romak, me kulmin e tij gjatë shekullit të 4 p.e.s, kur ishte një qendër e rëndësishme ekonomike dhe kulturore 
        në Mesdhe. Apollonia ishte e njohur për tregtinë e saj të begatë, planifikimin urban të sofistikuar dhe shkollën e famshme të filozofisë. 
        Parku përmban një përzierje të mrekullueshme të arkitekturës së lashtë greke dhe romake, duke përfshirë një teatër të ruajtur mirë, 
        tempuj, Bouleuterion-in (ndërtesa e këshillit), dhe mbetje të një forumi romak. Ndikimi i Apollonisë shtrihej përtej kufijve të saj, 
        duke kontribuar në përhapjen e kulturës greke në rajonin e Adriatikut. Rrënojat e paprekura të parkut ofrojnë një pamje të rrallë të 
        një qyteti dikur të lulëzuar, duke e bërë atë një vend kyç për të kuptuar historinë e lashtë të rajonit dhe një thesar të vërtetë për 
        arkeologët.<br /><br /><br /><br /><img src="APOLLONI_LOGO.png" width="200"/>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
    </div>
    </div>
    </div>
        )}


{/* Modal for modalObjekt23 */}
{activeModal === 'modalObjekt23' && (
    <div className="modal-overlay" tabIndex="-1" ref={modalRef} onClick={handleModalClick}>
            <div className="modal-content">
    <div className="modal-body">
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <button type="button" className="btn-close  close-button" onClick={closeModal} aria-label="Close"></button></div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-6 object3d"><small style={{background:'red'}} onClick={closeModal}>Press ESC to control object rotation</small>
          <iframe title=""
            allowFullScreen
            mozAllowFullScreen="true"
            webkitAllowFullScreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            width="100%"
            height="100%"
            src="https://sketchfab.com/models/1deee0d4807a4869a48b978994fa9866/embed?autostart=1"> 
          </iframe>
        </div>
        <div className="col-md-3 object-description" style={{ maxHeight: '650px', overflowY: 'scroll' }}>
        <b><h4>Pythean Apollo - Protector of the Arts</h4></b><small>Marble Sculpture, 2nd Century AD</small><br />
        This marble sculpture of Pythean Apollo, the protector of the arts, dates back to the 2nd century AD. Discovered in a Roman dwelling, 
        the sculpture represents the strong influence of Greek art and mythology on Roman culture, depicting Apollo in his classical, idealized 
        form, with a balanced and naturalistic stance. The craftsmanship showcases a high level of skill, especially in the naturalistic 
        rendering of the human form, a hallmark of Roman art inspired by Greek models. Apollo’s figure not only emphasizes his role as a 
        deity of the arts but also highlights the continued reverence of his cult in the Roman Empire, reflecting the close relationship 
        between art and religion during this period.<hr />
        <b><h4>Pythean Apollo, protector of the arts.</h4></b><small>Marble sculpture from 
        dwelling D. 2nd century AD</small><br /><br />Kjo skulpturë e Apollonit Pithian, mbrojtës i arteve, është realizuar në mermer dhe 
        daton nga shekulli II pas Krishtit. E gjetur në një banesë të periudhës romake, skulptura përfaqëson ndikimin e madh të artit dhe 
        mitologjisë greke mbi kulturën romake, duke pasqyruar Apollonin në formën e tij klasike të idealizuar, me trup të zhveshur dhe 
        qëndrim të balancuar. Detajet e punimit tregojnë mjeshtëri të lartë, sidomos në përshkrimin natyralist të formave njerëzore, 
        një karakteristikë e artit romak të periudhës, që frymëzohej nga modelet greke. Skulptura e Apollonit tregon jo vetëm rëndësinë 
        e tij si perëndi e arteve, por edhe vazhdimësinë e kultit të tij në Perandorinë Romake, duke dëshmuar për lidhjen e fortë midis 
        artit dhe fesë në këtë periudhë.<br /><br /><br /><br /><img src="APOLLONI_LOGO.png" width="200"/>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
    </div>
    </div>
    </div>
        )}

{activeModal === 'modalObjekt26' && (
    <div className="modal-overlay" tabIndex="-1" ref={modalRef} onClick={handleModalClick}>
            <div className="modal-content">
    <div className="modal-body">
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <button type="button" className="btn-close  close-button" onClick={closeModal} aria-label="Close"></button></div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-6 object3d"><small style={{background:'red'}} onClick={closeModal}>Press ESC to control object rotation</small>
          <iframe title=""
            allowFullScreen
            mozAllowFullScreen="true"
            webkitAllowFullScreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            width="100%"
            height="100%"
            src="https://sketchfab.com/models/c089057033ca433d94e25c23415e9930/embed?autostart=1"> 
          </iframe>
        </div>
        <div className="col-md-3 object-description" style={{ maxHeight: '650px', overflowY: 'scroll' }}>
        <b><h4>Pithos Used for Inhumation Burial</h4></b> 
        <small>Corinthian Production, 6th Century BC</small><br/><br/>
        This large pithos, crafted in Corinth during the 6th century BC, was used for inhumation burials. Such vessels were commonly
         used in ancient Greece, particularly for safe keeping the dead bodies before burial or cremation. The sturdy construction 
         and large size made these pithoi ideal for containing the remains, while their decorative elements reflect the artistic 
         style of Corinthian pottery of the period.<hr />
        <b><h4>Pithos i Përdorur për Varrosje</h4></b> 
        <small>Prodhim Korinthian, Shek. VI para Kr</small><br/><br/>
        Ky pithos i madh, i punuar në Korinth gjatë shekullit VI para Krishtit, përdorej për varrosje trupore. Vazo të tilla përdoreshin 
        shpesh në Greqinë e lashtë për ruajtjen e pjesëve trupore përpara varrosjes ose djegjes. Ndërtimi i tij i fortë dhe përmasat e 
        mëdha e bënin të përshtatshëm për të përmbajtur mbetjet, ndërsa elementet dekorative pasqyrojnë stilin artistik të qeramikës 
        korinthiane të periudhës.    
        <br /><br /><br />
        <img src="APOLLONI_LOGO.png" width="200"/>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
    </div>
    </div>
    </div>
        )}

{/* Modal for modalObjekt27 */}
{activeModal === 'modalObjekt27' && (
    <div className="modal-overlay" tabIndex="-1" ref={modalRef} onClick={handleModalClick}>
            <div className="modal-content">
    <div className="modal-body">
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <button type="button" className="btn-close  close-button" onClick={closeModal} aria-label="Close"></button></div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-6 object3d"><small style={{background:'red'}} onClick={closeModal}>Press ESC to control object rotation</small>
          <iframe title=""
            allowFullScreen
            mozAllowFullScreen="true"
            webkitAllowFullScreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            width="100%"
            height="100%"
            src="https://sketchfab.com/models/afbe71c49f814dfb847749f3e7f8b652/embed?autostart=1"> 
          </iframe>
        </div>
        <div className="col-md-3 object-description" style={{ maxHeight: '650px', overflowY: 'scroll' }}>
        <b><h4>APHRODITE</h4></b> 
        <small>Terracota of Apollonian production, found in Margëlliç, 2nd century BC </small><br/><br/>
        This clay figurine of Aphrodite, the goddess of love and beauty, was discovered in Margëlliç. Dating back to the 2nd century BC, 
        it represents the strong influence of Greek mythology in the region of modern-day Albania. The sculpture features refined details, 
        reflecting the Hellenistic aesthetic ideals and the craftsmanship of local artisans working with clay.<hr />
        <b><h4>AFËRDITA</h4></b> 
        <small>Personifikim i perëndeshës Afërditë, punuar me baltë. Zbuluar në Margëlliç, Shek. II para Kr.</small><br/><br/>
        Kjo figurë e Afërditës, perëndesha e dashurisë dhe bukurisë, është punuar me baltë dhe u zbulua në Margëlliç. Daton nga shekulli 
        II para Krishtit dhe përfaqëson ndikimin e fortë të mitologjisë greke në rajonin e Shqipërisë së sotme. Skulptura tregon detaje 
        të rafinuara, duke pasqyruar idealet estetike të periudhës helenistike dhe mjeshtërinë e artistëve vendas në përpunimin e baltes.       
        <br /><br /><br />
        <img src="APOLLONI_LOGO.png" width="200"/>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
    </div>
    </div>
    </div>
        )}



      <div id="helper-overlay" className="helper-overlay">
        <div className="helper-item">
          <i className="fas fa-mouse-pointer"></i> Click anywhere on the screen to enter 'first person view'<br />
          <i className="fas fa-sign-out-alt"></i> Press Esc to exit 'first person view'
        </div>
        <div className="helper-item">
          <i className="fas fa-arrows-alt"></i> Move forward-back-left-right using arrows of keyboard<br />
        </div>        
        <div className="helper-item">
          <i className="fas fa-mouse"></i> Move the mouse to look around when moving
        </div>
        <div className="helper-item">
          <i className="fas fa-plus"></i> Aim and click on any object to bring up a detailed info pop-up, and on the left side, you can rotate and explore each artifact in full 360-degree view.
        </div>
        <button id="dismiss-helper" className="helper-item dismiss-btn">Dismiss</button>
      </div>
    </div>
  );
}


function Scene({ onObjectClick }) 
{

  document.getElementById('dismiss-helper').addEventListener('click', function() {
    document.getElementById('helper-overlay').style.display = 'none';
  });
  const wallBoundingBoxes = [

    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(-25, -3, 5), new THREE.Vector3(0.5, 7, 50)),
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(-20, -1, 18), new THREE.Vector3(20, 10, 0.5)),
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(-20, -1, -18), new THREE.Vector3(20, 10, 0.5)),


    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(-12.6, 3.25, -11.5), new THREE.Vector3(0.2, 7, 13)),
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(-12.6, -1.5, 11.5), new THREE.Vector3(0.2, 7, 13)),    
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(10.1, 0, -7), new THREE.Vector3(14.8, 10, 0.2)),
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(2.6, 0, 0.4), new THREE.Vector3(0.2, 10, 15)),

    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(-10, -2.5, -5), new THREE.Vector3(2, 4, 2)),        // objekti 1
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(1, -1.5, -5), new THREE.Vector3(2, 5, 3)),          // objekti 2
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(-6.1, -1.57, 5), new THREE.Vector3(1.6, 5, 1.5)),   // objekti 3
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(-1, -0.5, 16.5), new THREE.Vector3(3, 5, 2)),       // objekti 4
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(4, 0, 2), new THREE.Vector3(4,2,4)), // objekti 5
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(-2, -0.5, -17.3), new THREE.Vector3(2, 5, 0.75)),   // objekti 6
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(-5.5, -1, -11), new THREE.Vector3(1.7, 7, 1.7)),    // objekti 7
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(3.2, 1, -27), new THREE.Vector3(1, 3, 3)),          // objekti 8
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(10, -2.2, -27.5), new THREE.Vector3(2, 4, 2)),      // objekti 9
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(16.8, -0.1, -22.2), new THREE.Vector3(1, 5, 3.9)),  // objekti 10
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(5.2, 0, 32), new THREE.Vector3(2.2, 8.5, 1.2)),     // objekti 11
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(11.8, 0, 31.7), new THREE.Vector3(4, 3, 2)),        // objekti 12
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(15.4, 0.3, 24.7), new THREE.Vector3(3, 5, 3)),      // objekti 13
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(15, -1, 13.2), new THREE.Vector3(3, 5, 4)),         // objekti 14  
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(24, -1, 3), new THREE.Vector3(2, 5, 2)),            // objekti 16     
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(25, -1, 21), new THREE.Vector3(2, 6, 2)),           // objekti 17
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(20, -1, 21), new THREE.Vector3(2, 6, 2)),           // objekti 18
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(30, -1, 21), new THREE.Vector3(2, 6, 2)),           // objekti 19
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(5.5, -3, 9), new THREE.Vector3(1.2, 6, 1.2)),       // objekti 27
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(10, 0.5, -6), new THREE.Vector3(8, 3, 1.8)),         // objekti 000  
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(25, -1, -22), new THREE.Vector3(3, 7, 3)),          // objekti 23

    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(10, 0, -32.9), new THREE.Vector3(15, 10, 0.2)),
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(17.6, 0, -25.5), new THREE.Vector3(0.2, 10, 15)),
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(2.6, 0, -25.3), new THREE.Vector3(0.2, 10, 15)),
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(-5, 0, -17.9), new THREE.Vector3(15, 10, 0.2)),
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(25.2, 0, -23.9), new THREE.Vector3(15, 10, 0.2)),
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(40.2, 0, 8), new THREE.Vector3(15, 10, 0.2)),
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(40.2, 0, -8.9), new THREE.Vector3(15, 10, 0.2)),
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(25.2, 0, 23), new THREE.Vector3(15, 10, 0.2)),
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(32.8, 0, 15.6), new THREE.Vector3(0.2, 10, 15)),
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(32.8, 0, -16.5), new THREE.Vector3(0.2, 10, 15)),
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(-5, 0, 17.9), new THREE.Vector3(15, 10, 0.2)),
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(2.6, 0, 25.4), new THREE.Vector3(0.2, 10, 15)),
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(10.01, 0, 33), new THREE.Vector3(15, 10, 0.2)),
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(17.6, 0, 20.6), new THREE.Vector3(0.2, 10, 25)),
    new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(47.8, 0, -0.4), new THREE.Vector3(0.5, 10, 17)),
  ];

  const [clicked, setClicked] = useState(false);
  const floorTexture = useLoader(THREE.TextureLoader, `${process.env.PUBLIC_URL}/beton.jpg`);
  const ceilingTexture = useLoader(THREE.TextureLoader, `${process.env.PUBLIC_URL}/concrete.jpg`);  
  const wallTexture = useLoader(THREE.TextureLoader, `${process.env.PUBLIC_URL}/wall.jpg`);
  const basementTexture = useLoader(THREE.TextureLoader, `${process.env.PUBLIC_URL}/marble.jpg`); 
  const wall3Texture = useLoader(THREE.TextureLoader, `${process.env.PUBLIC_URL}/muri5.jpg`);
  const wall5Texture = useLoader(THREE.TextureLoader, `${process.env.PUBLIC_URL}/muri5.jpg`);  
  const wall8Texture = useLoader(THREE.TextureLoader, `${process.env.PUBLIC_URL}/muri5.jpg`);
  const wall9Texture = useLoader(THREE.TextureLoader, `${process.env.PUBLIC_URL}/muri5.jpg`);
  const wall10Texture = useLoader(THREE.TextureLoader, `${process.env.PUBLIC_URL}/muri5.jpg`);

  const wall89Texture = useLoader(THREE.TextureLoader, `${process.env.PUBLIC_URL}/muri5.jpg`);
  const wall15Texture = useLoader(THREE.TextureLoader, `${process.env.PUBLIC_URL}/muri5.jpg`);
  const wall18Texture = useLoader(THREE.TextureLoader, `${process.env.PUBLIC_URL}/teksti.png`);
  const wall19Texture = useLoader(THREE.TextureLoader, `${process.env.PUBLIC_URL}/teksti.png`);


  wallTexture.wrapS = wallTexture.wrapT = THREE.RepeatWrapping;
  wallTexture.repeat.set(36, 36); // Adjust repetition values as needed
  
  wall3Texture.wrapS = wall3Texture.wrapT = THREE.RepeatWrapping;
  wall3Texture.repeat.set(36, 36); // Adjust repetition values as needed

  wall5Texture.wrapS = wall5Texture.wrapT = THREE.RepeatWrapping;
  wall5Texture.repeat.set(36, 36); // Adjust repetition values as needed

  wall8Texture.wrapS = wall8Texture.wrapT = THREE.RepeatWrapping;
  wall8Texture.repeat.set(36, 36); // Adjust repetition values as needed

  wall89Texture.wrapS = wall89Texture.wrapT = THREE.RepeatWrapping;
  wall89Texture.repeat.set(36, 36); // Adjust repetition values as needed

  wall9Texture.wrapS = wall9Texture.wrapT = THREE.RepeatWrapping;
  wall9Texture.repeat.set(36, 36); // Adjust repetition values as needed

  wall10Texture.wrapS = wall10Texture.wrapT = THREE.RepeatWrapping;
  wall10Texture.repeat.set(36, 36); // Adjust repetition values as needed


  wall18Texture.wrapS = wall18Texture.wrapT = THREE.RepeatWrapping;
  wall18Texture.repeat.set(36, 36); // Adjust repetition values as needed

  wall15Texture.wrapS = wall15Texture.wrapT = THREE.RepeatWrapping;
  wall15Texture.repeat.set(36, 36); // Adjust repetition values as needed

  wall19Texture.wrapS = wall19Texture.wrapT = THREE.RepeatWrapping;
  wall19Texture.repeat.set(36, 36); // Adjust repetition values as needed

  basementTexture.wrapS = basementTexture.wrapT = THREE.RepeatWrapping;
  basementTexture.repeat.set(36, 36); // Adjust repetition values as needed

  // Enable mipmaps for better performance
  floorTexture.generateMipmaps = true;
  wallTexture.generateMipmaps = true; 
  wall3Texture.generateMipmaps = true;
  wall5Texture.generateMipmaps = true;
  wall15Texture.generateMipmaps = true;
  wall18Texture.generateMipmaps = true;
  wall19Texture.generateMipmaps = true;
  wall8Texture.generateMipmaps = true;
  wall89Texture.generateMipmaps = true;
  wall9Texture.generateMipmaps = true;
  wall10Texture.generateMipmaps = true;

  basementTexture.generateMipmaps = true;

  floorTexture.minFilter = THREE.LinearMipmapLinearFilter;
  wallTexture.minFilter = THREE.LinearMipmapLinearFilter;
  wall3Texture.minFilter = THREE.LinearMipmapLinearFilter;
  wall5Texture.minFilter = THREE.LinearMipmapLinearFilter;
  wall8Texture.minFilter = THREE.LinearMipmapLinearFilter;
  wall89Texture.minFilter = THREE.LinearMipmapLinearFilter;
  wall10Texture.minFilter = THREE.LinearMipmapLinearFilter;

  wall15Texture.minFilter = THREE.LinearMipmapLinearFilter;
  wall18Texture.minFilter = THREE.LinearMipmapLinearFilter;
  wall19Texture.minFilter = THREE.LinearMipmapLinearFilter;
  basementTexture.minFilter = THREE.LinearMipmapLinearFilter;

  const handleClick = (modalId) => {
    setClicked(!clicked);
    console.log(`${modalId} clicked!`);
    onObjectClick(modalId); // Trigger the modal open function with specific ID
  };
  const handleClickQypi= () => {
    setClicked(!clicked); // Toggle clicked state
    console.log('Qypi clicked!');
  };

  const velocity = useRef(new THREE.Vector3());
  const direction = useRef(new THREE.Vector3());
  const [moveForward, setMoveForward] = useState(false);
  const [moveBackward, setMoveBackward] = useState(false);
  const [moveLeft, setMoveLeft] = useState(false);
  const [moveRight, setMoveRight] = useState(false);

  // Create a Box3 for the camera's bounding box
  const cameraBoundingBox = useRef(new THREE.Box3()).current;

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.code) {
        case 'KeyW': // Move forward
        case 'ArrowUp': // Move forward with Arrow Up
          setMoveForward(true);
          break;
        case 'KeyS': // Move backward
        case 'ArrowDown': // Move backward with Arrow Down
          setMoveBackward(true);
          break;
        case 'KeyA': // Move left
        case 'ArrowLeft': // Move left with Arrow Left
          setMoveLeft(true);
          break;
        case 'KeyD': // Move right
        case 'ArrowRight': // Move right with Arrow Right
          setMoveRight(true);
          break;
        default:
          break;
      }
    };
  
    const handleKeyUp = (event) => {
      switch (event.code) {
        case 'KeyW':
        case 'ArrowUp':
          setMoveForward(false);
          break;
        case 'KeyS':
        case 'ArrowDown':
          setMoveBackward(false);
          break;
        case 'KeyA':
        case 'ArrowLeft':
          setMoveLeft(false);
          break;
        case 'KeyD':
        case 'ArrowRight':
          setMoveRight(false);
          break;
        default:
          break;
      }
    };
  
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
  
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);
   

  const speed = 25; // Adjust this value to control movement speed
  const minHeight = -2; 
  const maxHeight = 5;
  const damping = 5; // Adjust damping to control how quickly the movement slows down

  function checkCollisions(cameraBoundingBox, wallBoundingBoxes) {
    for (const wallBox of wallBoundingBoxes) {
      if (cameraBoundingBox.intersectsBox(wallBox)) {
        const intersection = new THREE.Box3().intersectBoxes(cameraBoundingBox, wallBox);
        const size = new THREE.Vector3();
        intersection.getSize(size);  
        const normal = new THREE.Vector3();  
        // Determine the collision normal based on intersection size
        if (size.x > size.z) {
          normal.set(cameraBoundingBox.min.x < wallBox.min.x ? -1 : 1, 0, 0);
        } else {
          normal.set(0, 0, cameraBoundingBox.min.z < wallBox.min.z ? -1 : 1);
        }  
        // Log collision details
        console.log('Collision detected!');
        console.log('Camera Bounding Box:', cameraBoundingBox);
        console.log('Wall Bounding Box:', wallBox);
        console.log('Intersection Box:', intersection);
        console.log('Collision Normal:', normal);
  
        return { normal };
      }
    }
    return null;
  }
  function detectCollisions(newPosition) 
  {
    const cameraBoundingBox = new THREE.Box3().setFromCenterAndSize(newPosition, new THREE.Vector3(1, 1, 1)); // Adjust as needed
    return wallBoundingBoxes.some(box => cameraBoundingBox.intersectsBox(box));
  }
  useFrame((state, delta) => {
    const camera = state.camera;
  
    // Get the forward vector relative to the camera's orientation
    const forwardVector = new THREE.Vector3();
    camera.getWorldDirection(forwardVector);
    forwardVector.y = 0; // Prevent vertical movement
    forwardVector.normalize();
  
    // Get the right vector relative to the camera's orientation
    const rightVector = new THREE.Vector3();
    rightVector.crossVectors(forwardVector, camera.up).normalize(); // Ensure right vector is correct
  
    // Reset velocity before calculating new movement
    velocity.current.set(0, 0, 0);
  
    // Update velocity based on keyboard input
    if (moveForward) velocity.current.add(forwardVector.multiplyScalar(speed * delta));
    if (moveBackward) velocity.current.add(forwardVector.multiplyScalar(-speed * delta));
    if (moveLeft) velocity.current.add(rightVector.multiplyScalar(-speed * delta));  // Move left
    if (moveRight) velocity.current.add(rightVector.multiplyScalar(speed * delta));  // Move right
  
    // Calculate new position based on velocity
    const newPosition = camera.position.clone().add(velocity.current);
  
    // Check for collisions with walls before updating camera position
    if (!detectCollisions(newPosition)) {
      camera.position.copy(newPosition);
    } else {
      // Handle collision if detected
      const collisionInfo = checkCollisions(cameraBoundingBox, wallBoundingBoxes);
      if (collisionInfo) {
        handleCollision(state, collisionInfo, delta);
      }
    }  
    // Limit height of the camera and apply damping to velocity
    camera.position.y = Math.max(minHeight, Math.min(maxHeight, camera.position.y));  
    // Apply damping to smooth the movement stop
    const dampingFactor = Math.exp(-damping * delta);
    velocity.current.multiplyScalar(dampingFactor);  
    // Stop movement if velocity is below a certain threshold
    if (Math.abs(velocity.current.x) < 0.01) velocity.current.x = 0;
    if (Math.abs(velocity.current.z) < 0.01) velocity.current.z = 0;
  });
  

  
  function handleCollision(state, collisionInfo, delta) {
    const { normal } = collisionInfo;
  
    // Correct camera position slightly away from the collision surface
    if (normal.x !== 0) {
      state.camera.position.x -= normal.x * 0.1; // Adjust as necessary
      velocity.current.x = 0; // Stop horizontal movement
    }
    if (normal.z !== 0) {
      state.camera.position.z -= normal.z * 0.1; // Adjust as necessary
      velocity.current.z = 0; // Stop forward/backward movement
    }
    console.log('Collision handled. Adjusted position:', state.camera.position);
  }
  
  // Function to update the camera bounding box based on its position
  function updateCameraBoundingBox(camera, boundingBox) {
    boundingBox.setFromObject(camera);
  }

const gltfLoader = new GLTFLoader();
const dLoader = new DRACOLoader();
dLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.7/');
dLoader.setDecoderConfig({ type: 'js' });
gltfLoader.setDRACOLoader(dLoader); 
  return (
    <group>
{/*
      <Wall position={[-25, -3, 5]} scale={[0.5, 7, 50]} color="red"  />
      <Wall position={[-20, -1, 18]} scale={[20, 10, 0.5]} color="blue"  />
      <Wall position={[-20, -1, -18]} scale={[20, 10, 0.5]} color="pink"  />  
*/}

            

      <Wall position={[-12.75, 4.6, 0]} scale={[0.5, 5.3, 12]} color="#b1d8df"/>{/* MURI 1 - HYRJA LART */}
      <Wall position={[-12.75, 4.6, 12]} scale={[0.5, 5.3, 12]} color="#b1d8df"/>
      <Wall position={[-12.75, 4.6, -12]} scale={[0.5, 5.3, 12]}  color="#b1d8df"/>
      <Wall position={[-12.75, -1.40, -11.5]} scale={[0.5, 6.7, 13]} color="#b1d8df"/>
      <Wall position={[-12.75, -1.40, 11.5]} scale={[0.5, 6.7, 13]} color="#b1d8df"/>

      <Wall position={[-5, 1.25, 17.8]} scale={[15, 12, 0]} texture={wallTexture} color="#3c727e" />{/* MURI 2  */}
      <Wall3 position={[2.6, 1.25, 25.4]} scale={[0.2, 12, 15.2]} />{/* MURI 3  */}
      <Wall position={[10.01, 1.25, 33]} scale={[15, 12, 0.2]} texture={wallTexture} color="#214655" />   {/* MURI 4  */} 
      <Wall5 position={[17.6, 1.25, 20.45]} scale={[0.7, 12, 25]} texture={wall5Texture} />             {/* MURI 5  */}
      {/*<Wall position={[17.6, 1.25, 27.95]} scale={[0.7, 12, 10.9]} color="#fff" />  {/* MURI 5.1  */}
      <Wall position={[25.2, 1.25, 22.6]} scale={[15, 12, 0.2]} texture={wallTexture} color="#214655" />  {/* MURI 6  */}
      <Wall position={[32.8, 1.25, 15.6]} scale={[0.2, 12, 15]} texture={wallTexture} color="#275568" />  {/* MURI 7  */}
      <Wall8 position={[40.2, 1.25, 8]} scale={[15, 12, 0.2]} texture={wallTexture} color="#111111" />     {/* MURI 8  */}
      <Wall89 position={[47.8, 1.25, -0.4]} scale={[0.5, 12, 17]} texture={wall89Texture} color="#efefef" />    {/* MURI 9  */}
      <Wall10 position={[40.2, 1.25, -8.9]} scale={[15, 12, 0.2]} texture={wall10Texture} color="" />     {/* MURI 10  */}
      <Wall9 position={[32.8, 1.25, -16.5]} scale={[0.2, 12, 15]} texture={wallTexture} color="" />       {/* MURI 11  */}
      <Wall position={[25.2, 1.25, -23.9]} scale={[15, 12, 0.2]} texture={wallTexture} color="#285e74" /> {/* MURI 12  */}
      <Wall13 position={[17.6, 1.25, -25.5]} scale={[0.5, 12, 15]} color="" /> {/* MURI 13 */}
      <Wall position={[10, 1.25, -32.9]} scale={[15, 12, 0.2]} texture={wallTexture} color="#214655" />   {/* MURI 14 */}
      <Wall15 position={[2.6, 1.25, -25.3]} scale={[0.2, 12, 15]} texture={wallTexture} color="#FFF" />  {/* MURI 15  */}
      <Wall position={[-5, 1.25, -17.9]} scale={[15, 12, 0.2]} texture={wallTexture} color="#285e74" />   {/* MURI 16  */}      
      <Wall18 position={[2.5, -0.5, 0.4]} scale={[0.5, 9, 15.3]} texture={wall18Texture} color="#FFF" />  {/* MURI 18  */}      
      <Wall19 position={[10.25, -0.5, -7]} scale={[15, 9, 0.5]} texture={wall19Texture} color="" />     {/* MURI 19 */}
      <Wall position={[40.2, 8.2, -0.4]} scale={[15, 2, 17]} color="#000" />                              {/* ceiling */}
      <Wall position={[2.5, 8.2, 0]} scale={[30.5, 2, 36.5]} texture={wallTexture} color="#999" />        {/* ceiling */}
      <Wall position={[25.25, 8.2, -0.4]} scale={[15, 2, 47]}  color="#999" />                            {/* ceiling */}
      <Ceiling position={[10, 8.2, -30.85]}   scale={[15.5, 2, 4]}    texture={ceilingTexture}  color="#efefef" receiveShadow />
      <Ceiling position={[10, 8.2, -20.75]}   scale={[15.5, 2, 5]}    texture={ceilingTexture}  color="#efefef" receiveShadow />
      <Ceiling position={[5,  8.2, -26.05]}   scale={[5.5, 2, 5.6]}   texture={ceilingTexture}  color="#efefef" receiveShadow />
      <Ceiling position={[15, 8.2, -26.05]}   scale={[5.5, 2, 5.6]}   texture={ceilingTexture}  color="#efefef" receiveShadow />
      <Ceiling position={[10, 8.2, 25.75]}   scale={[15.5, 2, 15]}    texture={ceilingTexture}  color="#efefef" castShadow receiveShadow />
      <Floor position={[10, -5, 0]}         scale={[45.5, 0.5, 66.7]} texture={floorTexture} receiveShadow color="#efefef"/>
      <Floor position={[40.5, -5, -0.4]}    scale={[15.5, 0.5, 18]} texture={floorTexture} receiveShadow color="#000"/>
    
      {/* OBJEKTI 1 <BoundingBox position={[-9, -2.5, -5]} dimensions={[2, 4, 2]} /> */}
      <ObjectBasement position={[-10, -3.5, -5]} scale={[1.5, 2.8, 1.5]} texture={basementTexture} color="#3c727e" />
      <group><SceneObject url={`https://cdn3dmuseum.s3.eu-central-1.amazonaws.com/1_KRATER.glb`} position={[-10, -1, -5]} scale={[2, 2, 2]} onClick={() => handleClick('modalObjekt1')} /> </group>
      
      
      {/* OBJEKTI 2  <BoundingBox position={[1, -1.5, -5]} dimensions={[2, 5, 3]} /> */}
      <Wall position={[1, -4.5, -5]} scale={[3, 1.5, 3]} texture={basementTexture} color="#3c727e" />
      <group> <SceneObject url={`https://cdn3dmuseum.s3.eu-central-1.amazonaws.com/2_MAGJISTRATI.glb`} position={[1.3, -1.2, -5]} scale={[3, 3, 3]} onClick={() => handleClick('modalObjekt2')}  /> </group>
      


      {/* OBJEKTI 15 VAZO_MADHE QYPI_MADH  <BoundingBox position={[5.5, -3, 9]} dimensions={[1.5, 4, 1.5]} /> */}       
      <group><SceneObject url={`https://cdn3dmuseum.s3.eu-central-1.amazonaws.com/15_VAZO_MADHE_APOLLONI_1.glb`} position={[9, -4.7, 2]} scale={[1, 1, 1]} onClick={() => handleClick('modalObjekt26')} /> </group>
      
      
      {/* OBJEKTI 15 <BoundingBox position={[10.1, -2, 1]} dimensions={[5, 2, 3]} />*/}
      <ObjectBasement position={[15, -3.1, -8.5]} scale={[1.5, 4, 1.5]} texture={basementTexture} color="#3c727e" />
      <group><SceneObject url={`https://cdn3dmuseum.s3.eu-central-1.amazonaws.com/25_APOLLON.glb`} position={[15, -1.2, -8.5]} scale={[0.9, 0.9, 0.9]} onClick={() => handleClick('modalObjekt3')} rotation={[0, Math.PI / 2, 0]} castShadow receiveShadow /> </group>
      {/* OBJEKTI 4 <BoundingBox position={[-1, -0.5, 16.5]} dimensions={[3, 5, 2]} /> */}

      <ObjectBasement position={[-1, -4, 16.5]} scale={[3, 1.5, 2]} texture={wallTexture} color="#3c727e" />
      <group> <SceneObject url={`https://cdn3dmuseum.s3.eu-central-1.amazonaws.com/4_BURRE_I_PLOTE.glb`} position={[-1, 0.2, 16.8]} scale={[0.152, 0.152, 0.152]}  onClick={() => handleClick('modalObjekt4')} castShadow receiveShadow /> </group>
      {/* OBJEKTI 5  
      <BoundingBox position={[4, 0, 2]} dimensions={[4, 2, 4]} />*/}
      <group rotation={[0, Math.PI, 0]}>
        <SceneObject  url={`https://cdn3dmuseum.s3.eu-central-1.amazonaws.com/5_RelievBanketi1.glb`} 
        position={[-3, -0.8, -1]} 
        scale={[0.45, 0.45, 0.45]}  
        onClick={() => handleClick('modalObjekt5')} /> </group>      
      
      
      {/* OBJEKTI 6  <BoundingBox position={[-2, -0.5, -17.3]} dimensions={[2, 5, 0.75]} /> */}
      <group rotation={[0, Math.PI, 0]}><SceneObject url={`https://cdn3dmuseum.s3.eu-central-1.amazonaws.com/6_RELIEV_GRUAJE.glb`} position={[2, 1.5, 17.25]} scale={[2.5, 2.5, 2.5]} onClick={() => handleClick('modalObjekt6')} /> </group>
      {/* OBJEKTI 7  <BoundingBox position={[-5.5, -1, -11]} dimensions={[1.7, 7, 1.7]} />*/}      
      <group rotation={[0, Math.PI, 0]}> <SceneObject url={`https://cdn3dmuseum.s3.eu-central-1.amazonaws.com/7_KOLONE_KORINTHI.glb`} position={[5.5, -2, 11]} scale={[4.2, 4.2, 4.2]}  onClick={() => handleClick('modalObjekt7')} /> </group>
      {/* OBJEKTI 8  <BoundingBox position={[3.2, 1, -27]} dimensions={[1, 3, 3]} />*/}     
      <group rotation={[0, Math.PI, 0]}> <SceneObject url={`https://cdn3dmuseum.s3.eu-central-1.amazonaws.com/8_GLADIATOR.glb`} position={[-3.5, 1, 27]} scale={[2.3, 2.3, 2.3]}  onClick={() => handleClick('modalObjekt8')} /></group>
      {/* OBJEKTI 9 <BoundingBox position={[10, -2.2, -27.5]} dimensions={[2, 4, 2]} />*/}
      <ObjectBasement position={[10, -3, -27.5]} scale={[2, 3, 2]} texture={wallTexture} color="#1a4751" /> 
      <group rotation={[0, Math.PI, 0]}> <SceneObject url={`https://cdn3dmuseum.s3.eu-central-1.amazonaws.com/9_ARISTOKRATI.glb`} position={[-9.65, -5.1, 27.5]} scale={[5, 5, 5]} onClick={() => handleClick('modalObjekt9')}  /></group>
      {/* OBJEKTI 10 <BoundingBox position={[16.8, -0.1, -25.2]} dimensions={[1, 5, 3.9]} /> */} 
      <group rotation={[0, Math.PI, 0]}> <SceneObject url={`https://cdn3dmuseum.s3.eu-central-1.amazonaws.com/10_RELIEV_CIFTI.glb`} position={[-16.8, 0, 22]} scale={[12, 12, 12]} onClick={() => handleClick('modalObjekt10')}  /> </group>
      {/* OBJEKTI 11 <BoundingBox position={[5.2, 0, 32]} dimensions={[2.2, 8.5, 1.2]} /> */}      
      <group> <SceneObject url={`https://cdn3dmuseum.s3.eu-central-1.amazonaws.com/11_STELE_E_MADHE.glb`} position={[5, -0.5, 35.8]} scale={[3.3, 3.3, 3.3]} onClick={() => handleClick('modalObjekt11')} /> </group>
      {/* OBJEKTI 12 <BoundingBox position={[11.8, 0, 31.7]} dimensions={[4, 3, 2]} /> */}      
      <ObjectBasement position={[12, -1.45, 31.9]}  scale={[4.5, 0.2, 1.8]} texture={wallTexture} color="#FFF" />
      <group> <SceneObject url={`https://cdn3dmuseum.s3.eu-central-1.amazonaws.com/12_VJOSA.glb`} position={[12.5, -1, 32]} scale={[2.5, 2.5, 2.5]} onClick={() => handleClick('modalObjekt12')} /> </group>
      {/* MBAJTESE OBJEKTI 13 <BoundingBox position={[15.4, 0.3, 24.7]} dimensions={[3, 5, 3]} /> */}      
      <ObjectBasement position={[15.5, -4.5, 24.7]} scale={[3, 5, 3]} texture={wallTexture} color="#3c727e" />     
      <group> <SceneObject url={`https://cdn3dmuseum.s3.eu-central-1.amazonaws.com/13_EJLLI.glb`} position={[15.4, 0.3, 25]} scale={[2.1, 2.1, 2.1]} onClick={() => handleClick('modalObjekt13')} /> </group>
      {/* OBJEKTI 14 <BoundingBox position={[15, -1, 13.2]} dimensions={[3, 5, 4]} /> */}
      <ObjectBasement position={[15.9, -3.8, 13.2]} scale={[3.5, 2, 4.5]} texture={wallTexture} color="#3c727e" /> 
      <group><SceneObject url={`https://cdn3dmuseum.s3.eu-central-1.amazonaws.com/14_USHTARI_PLAGOSUR.glb`} position={[16.8, -3.9, 14]} scale={[4.5, 4.5, 4.5]} onClick={() => handleClick('modalObjekt14')}/></group>         
      {/* OBJEKTI 15 <BoundingBox position={[-6.1, -1.57, 5]} dimensions={[1.6, 5, 1.5]} /> */}      
      <group><SceneObject url={`https://cdn3dmuseum.s3.eu-central-1.amazonaws.com/15_VAZO_MBI_GURE.glb`} position={[-6.1, -4, 5]} scale={[1.8, 1.8, 1.8]} onClick={() => handleClick('modalObjekt15')} /> </group>

      {/* qypi madh [10, -3.8, 1]*/}

      {/* OBJEKTI 000 <BoundingBox position={[10, 0.5, -6]} dimensions={[8, 3, 1.8]} /> */}
      <ObjectBasement position={[10, -0.5, -6]} scale={[8, 0.2, 1.5]} color="#fff" />
      <ObjectBasement position={[10, 0.3, -6.5]} scale={[8, 1, 0.05]} color="#fff" />
      
      
      <group> <SceneObject url={`https://cdn3dmuseum.s3.eu-central-1.amazonaws.com/001_EROS_DELFIN_2.glb`} position={[6.8, 0.4, -6]} scale={[0.55, 0.55, 0.55]} onClick={() => handleClick('modalObjekt0001')} /></group>
      <group><SceneObject url={`https://cdn3dmuseum.s3.eu-central-1.amazonaws.com/002_BURRI_VOGEL.glb`} position={[8.8, -0.5, -6]} scale={[5.5, 5.5, 5.5]} onClick={() => handleClick('modalObjekt0004')} /> </group>
      <group><SceneObject url={`https://cdn3dmuseum.s3.eu-central-1.amazonaws.com/003_ZEUSI.glb`} position={[11, 0.5, -6]}scale={[0.15, 0.15, 0.15]} onClick={() => handleClick('modalObjekt0003')} /> </group>
      <group><SceneObject url={`https://cdn3dmuseum.s3.eu-central-1.amazonaws.com/15_SFINKS_1.glb`} position={[13.5, -0.3, -7]} scale={[5, 5, 5]} onClick={() => handleClick('modalObjekt0002')}/> </group>
      

      
      
      {/* OBJEKTI 16 <BoundingBox position={[24, -1, 3]} dimensions={[2, 5, 2]} /> */}
      <Wall position={[24, -3, 3]}  scale={[2, 4, 2]} texture={wallTexture} color="#3c727e" />
      <group><SceneObject url={`https://cdn3dmuseum.s3.eu-central-1.amazonaws.com/16_ATHINAJA.glb`} position={[24.5, -0.2, 3.4]}   scale={[3, 3, 3]}   onClick={() => handleClick('modalObjekt16')}  /></group>
      {/* OBJEKTI 17 <BoundingBox position={[30, -1, 21]} dimensions={[2, 6, 2]} /> */}
      <Wall position={[30, -3, 21]} scale={[1.5, 5, 1.5]} texture={wallTexture} color="#3c727e" />
      <group><SceneObject url={`https://cdn3dmuseum.s3.eu-central-1.amazonaws.com/17_PORTRET_BURR.glb`} position={[20, 0.2, 21]} scale={[1, 1, 1]}   onClick={() => handleClick('modalObjekt17')}  /></group>
      {/* OBJEKTI 18 <BoundingBox position={[25, -1, 21]} dimensions={[2, 6, 2]} /> */}
      <Wall position={[25, -3, 21]} scale={[1.5, 5, 1.5]} texture={wallTexture}        color="#3c727e" />
      <group><SceneObject url={`https://cdn3dmuseum.s3.eu-central-1.amazonaws.com/18_ARTEMIS.glb`} position={[24.9, -1.2, 21]} scale={[0.9, 0.9, 0.9]} onClick={() => handleClick('modalObjekt18')}  /></group>
      {/* OBJEKTI 19 <BoundingBox position={[20, -1, 21]} dimensions={[2, 6, 2]} /> */}
      <Wall position={[20, -3, 21]} scale={[1.5, 5, 1.5]} texture={wallTexture}        color="#3c727e" />
      <group><SceneObject url={`https://cdn3dmuseum.s3.eu-central-1.amazonaws.com/19_BURRE.glb`} position={[30, -0.2, 21]} scale={[1.5, 1.5, 1.5]}   onClick={() => handleClick('modalObjekt19')}  /> </group>             
      {/* MBAJTESE OBJEKTI 21 <ObjectBasement position={[40, -3.8, 0]}  scale={[10, 2, 10]} texture={wallTexture} color="#3c727e" /> */}
      <group>
    {/*<spotLight
    position={[42, 10, 0]}        // Position the light higher above the object
    angle={Math.PI / 4}           // Wider angle for better light spread
    penumbra={0.3}                // Slightly soften the edges
    intensity={3}                 // Increase brightness for visibility
    distance={20}                 // Increase distance to cover a larger area
    castShadow                    // Enable shadows
    color="red"                   // Red spotlight
  />

 
  <object3D position={[42, -1, 0]} /> */}
        <SceneObject url={`https://cdn3dmuseum.s3.eu-central-1.amazonaws.com/21_APOLLONIA_SM.glb`} position={[42, -0.5, -2]} scale={[0.5, 0.5, 0.5]} onClick={() => handleClick('modalObjekt20')} /> </group>
      {/* OBJEKTI 23 <BoundingBox position={[25, -1, -22]} dimensions={[3, 7, 3]} /> */}
      <ObjectBasement position={[25, -5, -22]} scale={[3.5, 5, 3]} texture={wallTexture} color="#777" />
      <group><SceneObject url={`https://cdn3dmuseum.s3.eu-central-1.amazonaws.com/23_BRESHKA.glb`} position={[25, 0.5, -21.7]} scale={[2.5, 2.5, 2.5]} onClick={() => handleClick('modalObjekt23')}  /> </group>

       {/* OBJEKTI 27  <BoundingBox position={[5.5, -3, 9]} dimensions={[1.5, 4, 1.5]} /> */}       
       <ObjectBasement position={[5.5, -3, 9]} scale={[1.5, 4, 1.5]} texture={basementTexture} color="#3c727e" />
      <group><SceneObject url={`https://cdn3dmuseum.s3.eu-central-1.amazonaws.com/27_Statuje_vogel.glb`} position={[5.5, 0, 9]} scale={[0.9, 0.9, 0.9]} onClick={() => handleClick('modalObjekt27')} /> </group>
      

    </group>
  );
}




export default Museum;